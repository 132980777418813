<template>
    <v-container>
        <v-skeleton-loader v-if="this.api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-0">
                <v-container>
                    <v-data-table :items="formattedEventSales" :headers="eventSalesHeader" :search="searchResult"
                        class="elevation-1" dense>
                        <template v-slot:top>
                            <v-container>
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>
                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
        }),
        formattedEventSales() {
            return this.eventSales.map(item => ({
                ...item,
                sales_funnel: item.sales_funnel || '-',
                funnel_event_date: this.convertTimeToDay(item.funnel_event_date),
            }));
        },
    },
    props: [
    ],
    data: () => ({
        eventSales: null,
        eventSalesHeader: [
            {
                text: 'Event',
                value: 'sales_funnel',
            },
            {
                text: 'Event Date',
                value: 'funnel_event_date',
            },
            {
                text: 'Total',
                value: 'event_sales',
            },
        ],
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
        },
        searchResult: null,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getEventSales") {
                this.eventSales = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            let fetchEventSalesApi = this.fetchEventSales();
            this.$axios.all([
                this.$api.fetch(fetchEventSalesApi),
            ]);
        },
        fetchEventSales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/analysis/sales/event';
            return tempApi;
        },
        convertTimeToDay(time) {
            return time ? this.$moment(time).format('LL') : '-';
        },
    },
}
</script>
