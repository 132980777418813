<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                    Revenue By Month
                </v-card-title><br>
                <v-select dense outlined label="Year" class="py-0 my-0" :items="yearOptions" v-model="year">
                </v-select>
                <!-- <div class="px-5">
                        <PageRevenueByMonthTable :year="year" />
                    </div> -->
                <div class="px-5">
                    <PageRevenueByMonthChart :year="year" />
                </div>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" sm="12" class="px-0 ">
                <v-card elevation="1" class="px-5" outlined>
                    <br>
                    <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                        Revenue By Year
                    </v-card-title>
                    <div class="px-5">
                        <PageRevenueByYearTable />
                    </div>
                    <div class="px-5">
                        <PageRevenueByYearChart />
                    </div>
                </v-card>
            </v-col>
        </v-row> -->
    </v-container>
</template>
<script>
// import PageRevenueByYearChart from '../analysis/PageRevenueByYearChart.vue';
import PageRevenueByMonthChart from '../analysis/PageRevenueByMonthChart.vue';
// import PageRevenueByMonthTable from '../analysis/PageRevenueByMonthTable.vue';
// import PageRevenueByYearTable from '../analysis/PageRevenueByYearTable.vue';
export default {
    components: {
        // PageRevenueByMonthTable,
        // PageRevenueByYearTable,
        PageRevenueByMonthChart,
        // PageRevenueByYearChart
    },
    data() {
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());

        return {
            yearOptions: yearOptions,
            year: currentYear.toString(),
        };
    },
    methods: {
       
    }
}
</script>