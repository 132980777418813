<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                    Renewal By Month
                </v-card-title>
                <div class="px-5">
                    <PageRenewalTable :year="year" />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PageRenewalTable from '../analysis/PageRenewalTable.vue';
export default {
    components: {
        PageRenewalTable,
    },
    data() {
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());

        return {
            yearOptions: yearOptions,
            year: currentYear.toString(),
        };
    },
    methods: {
    }
}
</script>