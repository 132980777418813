<template>
    <v-container>
        <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table"></v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-0">
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-select dense outlined class="py-0 my-0" v-model="selectedYear" :items="years" label="Year"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select dense outlined class="py-0 my-0" v-model="selectedSubscriptionYear" :items="subscriptionYears"
                                label="Subscription Year"></v-select>
                        </v-col>
                    </v-row>
                    <v-data-table :items="filteredData" :headers="renewalByMonthHeader" class="elevation-1" dense>
                        <template v-slot:top>
                            <v-container>
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult"
                                        label="Search"></v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.Month }}</td>
                                <td>{{ item.Cancelled }}</td>
                                <td>{{ item.Confirmed }}</td>
                                <td>{{ item.NA }}</td>
                                <td>{{ item.Pending }}</td>
                                <td>{{ item.Renewed }}</td>
                                <td>{{ item.TotalCount }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
export default {
    props: {
        year: String,
    },
    data() {
        return {
            searchResult: null,
            renewalByMonth: null,
            renewalByMonthHeader: [
                { text: 'Month', value: 'Month' },
                { text: 'Cancelled', value: 'Cancelled' },
                { text: 'Confirmed', value: 'Confirmed' },
                { text: 'N/A', value: 'NA' },
                { text: 'Pending', value: 'Pending' },
                { text: 'Renewed', value: 'Renewed - Year 2' },
                { text: 'Total Count', value: 'Total Count' },
            ],
            api: {
                isSuccesful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            selectedSubscriptionYear: null,
            selectedYear: null,
        };
    },
    computed: {
        getFlattenedData() {
            const flattenedData = [];

            for (const subscriptionYear in this.renewalByMonth) {
                if (Object.hasOwnProperty.call(this.renewalByMonth, subscriptionYear)) {

                    const yearData = this.renewalByMonth[subscriptionYear];
                    const yearlyArray = [];

                    for (const year in yearData) {

                        if (Object.hasOwnProperty.call(yearData, year)) {
                            const monthData = yearData[year];
                            const monthlyArray = [];

                            for (const month in monthData) {

                                if (Object.hasOwnProperty.call(monthData, month)) {

                                    const statusData = monthData[month];
                                    const statusArray = [];

                                    for (const status in statusData) {

                                        if (Object.hasOwnProperty.call(statusData, status)) {

                                            const count = statusData[status];

                                            statusArray.push({
                                                "Status": status,
                                                "Count": count
                                            });

                                        }
                                    }

                                    monthlyArray.push({
                                        "Month": month,
                                        "StatusArray": statusArray
                                    });

                                }
                            }

                            yearlyArray.push({
                                "Year": year,
                                "MonthlyArray": monthlyArray
                            });

                        }
                    }

                    flattenedData.push({
                        "SubscriptionYear": subscriptionYear,
                        "YearlyArray": yearlyArray
                    });

                }
            }

            return flattenedData;
        },
        filteredData() {
            if (!this.selectedSubscriptionYear || !this.selectedYear) {
                return [];
            }

            const selectedData = this.getFlattenedData.find(
                (item) => item.SubscriptionYear === this.selectedSubscriptionYear
            );

            if (!selectedData || !selectedData.YearlyArray) {
                return [];
            }

            const yearData = selectedData.YearlyArray.find(
                (yearlyData) => yearlyData.Year === this.selectedYear
            );

            if (!yearData || !yearData.MonthlyArray) {
                return [];
            }

            return yearData.MonthlyArray.map((monthlyData) => ({
                Month: monthlyData.Month,
                Cancelled: this.getStatusCount(monthlyData, 'Cancelled'),
                Confirmed: this.getStatusCount(monthlyData, 'Confirmed'),
                NA: this.getStatusCount(monthlyData, 'N/A'),
                Pending: this.getStatusCount(monthlyData, 'Pending'),
                Renewed: (
                    this.getStatusCount(monthlyData, 'Renewed - Year 2') ||
                    this.getStatusCount(monthlyData, 'Renewed - Year 3')),
                TotalCount: this.getStatusCount(monthlyData, 'Total Count'),
            }));
        },
        subscriptionYears() {
            const allSubscriptionYears = this.getFlattenedData.map((item) => item.SubscriptionYear);

            const uniqueSubscriptionYears = Array.from(new Set(allSubscriptionYears));

            const filteredSubscriptionYears = uniqueSubscriptionYears.filter(year => year >= 2);

            return filteredSubscriptionYears;
        },
        years() {
            // if (!this.selectedSubscriptionYear) {
            //     return [];
            // }

            const currentYear = new Date().getFullYear();
            const yearsForSubscription = Array.from({ length: currentYear - 2021 }, (_, index) => (currentYear - index).toString());

            // const yearsForSubscription = this.getFlattenedData
            //     .filter((item) => item.SubscriptionYear === this.selectedSubscriptionYear)
            //     .reduce((years, item) => {
            //         return years.concat(item.YearlyArray.map((yearlyData) => yearlyData.Year));
            //     }, []);

            return Array.from(new Set(yearsForSubscription));
        },
    },
    methods: {
        fetchRenewalByMonth() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/analysis/renewal/month';
            return tempApi;
        },
        fetch() {
            let fetchRenewalByMonthApi = this.fetchRenewalByMonth();
            this.$axios.all([this.$api.fetch(fetchRenewalByMonthApi)]);
        },
        getStatusCount(item, status) {
            const statusArray = item.StatusArray || [];
            const statusObject = statusArray.find(s => s.Status === status);
            return statusObject ? statusObject.Count : 0;
        },
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getRenewalByMonth") {
                this.renewalByMonth = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        };
    },
    mounted() {
        this.fetch();
    },
};
</script>
  