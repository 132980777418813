<template>
    <v-container>
        <v-skeleton-loader v-if="this.api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-0">
                <v-container>
                    <v-data-table :items="formattedIndividualSales" :headers="individualSalesHeader"
                    :search="searchResult" class="elevation-1" dense>
                        <template v-slot:top>
                            <v-container>
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>
                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({}),
        formattedIndividualSales() {
            return this.individualSales.map(item => ({
                ...item,
                salesperson_name: (item.salesperson_name && item.salesperson_name !== '#VALUE!') ? item.salesperson_name : '-',
            }));
        },
    },
    props: [
    ],
    data: () => ({
        individualSales: null,
        individualSalesHeader: [
            {
                text: 'Individual',
                value: 'salesperson_name',
            },
            {
                text: 'Total',
                value: 'individual_sales',
            },

        ],
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
        },
        searchResult: null,
    }),

    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getIndividualSales") {
                this.individualSales = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            let fetchIndividualSalesApi = this.fetchIndividualSales();
            this.$axios.all([
                this.$api.fetch(fetchIndividualSalesApi),
            ]);
        },
        fetchIndividualSales() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + '/analysis/sales/individual';
            return tempApi;
        },

    },
}
</script>