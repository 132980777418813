<template>
    <v-container>
        <v-card flat>
            <div>
                <v-card-text class="d-flex justify-center align-item ">
                    <v-progress-circular v-if="api.isLoading" :size="50" color="primary"
                        style="align-items: center;position:absolute" indeterminate>
                    </v-progress-circular>
                    <canvas v-show="!api.isLoading" ref="myChart">

                    </canvas>
                </v-card-text>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import Chart from 'chart.js'

export default {
    props: [
        'status'
    ],
    data() {
        return {
            salesByYear: null,
            api: {
                isSuccesful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            trendData: null,
        };
    },

    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {

            if (resp.class === "getSalesByYear") {
                this.salesByYear = resp.data;

                const ctx = this.$refs.myChart.getContext('2d')

                if (this.chart) {
                    this.chart.destroy();
                }

                let year = this.salesByYear.map(entry => entry.year);
                let total = this.salesByYear.map(entry => entry.total_sales);

                this.trendData = [
                    {
                        x: year,
                        y: total,
                    }
                ];
                this.trendData = {
                    datasets: [total]
                }
                let config = {
                    type: 'bar',
                    data: {
                        labels: year,
                        datasets: [
                            {
                                label: 'Total Sales',
                                data: total,
                                borderWidth: 1,
                                borderColor: 'rgb(67, 209, 135)',
                                fill: false,
                            },
                        ]
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                }
                            }]
                        }
                    },

                }
                this.chart = new Chart(ctx, config);
            }

            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchSalesByYearApi = this.fetchSalesByYear();
            this.$api.fetch(fetchSalesByYearApi);
        },
        fetchSalesByYear() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/analysis/sales/year";
            return tempApi;
        },
    }
}
</script>