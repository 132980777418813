<template>
    <v-container>
        <v-toolbar class="pa-4" flat>
            <v-tabs v-model="typeTab">
                <v-btn color="primary" class="mx-2" outlined>
                    <v-tab>
                        User
                    </v-tab>
                </v-btn>
                <v-btn color="primary" class="mx-2" outlined>
                    <v-tab>
                        Company
                    </v-tab>
                </v-btn>
                <v-btn color="primary" class="mx-2" outlined>
                    <v-tab>
                        Renewal
                    </v-tab>
                </v-btn>
                <v-btn color="primary" class="mx-2" outlined>
                    <v-tab>
                        Sales
                    </v-tab>
                </v-btn>
                <v-btn color="primary" class="mx-2" outlined>
                    <v-tab>
                        Revenue
                    </v-tab>
                </v-btn>
            </v-tabs>
        </v-toolbar>

        <v-tabs-items v-model="typeTab">
            <v-tab-item>
                <PageActiveUsersAnalysis />
                <br>
            </v-tab-item>
            <v-tab-item>
                <PageActiveCompaniesAnalysis />
                <br>
            </v-tab-item>
            <v-tab-item>
                <PageRenewalAnalysis />
                <br>
            </v-tab-item>
            <v-tab-item>
                <PageSalesAnalysis />
                <br>
            </v-tab-item>
            <v-tab-item>
                <PageRevenueAnalysis />
                <br>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>
<script>
import PageActiveCompaniesAnalysis from './PageActiveCompaniesAnalysis.vue';
import PageActiveUsersAnalysis from './PageActiveUsersAnalysis.vue';
import PageRevenueAnalysis from './PageRevenueAnalysis.vue';
import PageSalesAnalysis from './PageSalesAnalysis.vue';
import PageRenewalAnalysis from './PageRenewalAnalysis.vue';

export default {
    components: {
        PageActiveCompaniesAnalysis,
        PageActiveUsersAnalysis,
        PageSalesAnalysis,
        PageRevenueAnalysis,
        PageRenewalAnalysis,
    },
    methods: {
    },
    data: () => ({
        typeTab: null,
        task: null,
        userRole: null,
        isPending: false,
        searchResult: null,
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
        }
    }),

}
</script>