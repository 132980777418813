<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                    Active User By Month
                </v-card-title><br>
                <v-select dense outlined label="Year" class="py-0 my-0" :items="yearOptions" v-model="year">
                </v-select>
                <!-- <div class="px-5">
                        <PageActiveUserByMonthTable :year="year" />
                    </div> -->
                <PageActiveUserByMonthChart :year="year" />
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" sm="12" class="px-0 ">
                <v-card elevation="1" class="px-5" outlined>
                    <br>
                    <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                        Active User By Year
                    </v-card-title>
                    <div class="px-5">
                        <PageActiveUserByYearTable />
                    </div>
                    <div class="px-5">
                        <PageActiveUserByYearChart />
                    </div>
                </v-card>
            </v-col>
        </v-row> -->
    </v-container>
</template>
<script>
import PageActiveUserByMonthChart from '../analysis/PageActiveUserByMonthChart.vue';
// import PageActiveUserByMonthTable from '../analysis/PageActiveUserByMonthTable.vue';
// import PageActiveUserByYearChart from '../analysis/PageActiveUserByYearChart.vue';
// import PageActiveUserByYearTable from '../analysis/PageActiveUserByYearTable.vue';
export default {
    components: {
        // PageActiveUserByYearTable,
        // PageActiveUserByMonthTable,
        PageActiveUserByMonthChart,
        // PageActiveUserByYearChart
    },
    data() {
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());

        return {
            yearOptions: yearOptions,
            year: currentYear.toString(),
        };
    },
    methods: {
     
    }
}
</script>