<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                    Active Company By Month
                </v-card-title><br>
                <v-select dense outlined label="Year" class="py-0 my-0" :items="yearOptions" v-model="year"
                    >
                </v-select>
                <!-- <div class="px-5">
                        <PageActiveCompanyByMonthTable :year="year"/>
                    </div> -->
                <PageActiveCompanyByMonthChart :year="year" />
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col cols="12" sm="12" class="px-0 ">
                <v-card elevation="1" class="px-5" outlined>
                    <br>
                    <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                        Active Company By Year
                    </v-card-title>
                    <div class="px-5">
                        <PageActiveCompanyByYearTable />
                    </div>
                    <div class="px-5">
                        <PageActiveCompanyByYearChart />
                    </div>
                </v-card>
            </v-col>
        </v-row> -->
    </v-container>
</template>
<script>
import PageActiveCompanyByMonthChart from '../analysis/PageActiveCompanyByMonthChart.vue';
// import PageActiveCompanyByMonthTable from '../analysis/PageActiveCompanyByMonthTable.vue';
// import PageActiveCompanyByYearChart from '../analysis/PageActiveCompanyByYearChart.vue';
// import PageActiveCompanyByYearTable from '../analysis/PageActiveCompanyByYearTable.vue';
export default {
    components: {
        // PageActiveCompanyByMonthTable,
        PageActiveCompanyByMonthChart,
        // PageActiveCompanyByYearTable,
        // PageActiveCompanyByYearChart
    },
    data() {
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());

        return {
            yearOptions: yearOptions,
            year: currentYear.toString(),
        };
    },
    methods: {
        
    }
}
</script>