<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="px-5">
                <v-toolbar flat class="">
                    <v-tabs v-model="salesTab" class="d-flex justify-center" style="height: 45px;">
                        <v-tab>
                            Month
                        </v-tab>
                        <v-tab>
                            Year
                        </v-tab>
                        <v-tab>
                            Team
                        </v-tab>
                        <v-tab>
                            Individual
                        </v-tab>
                        <v-tab>
                            Event
                        </v-tab>
                        <v-tab>
                            Speaker
                        </v-tab>
                        <v-tab>
                            Topic
                        </v-tab>
                    </v-tabs>
                </v-toolbar>

                <v-tabs-items v-model="salesTab">
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Sales By Month
                                </v-card-title><br>
                                <v-select dense outlined label="Year" class="py-0 my-0" :items="yearOptions" v-model="year"
                                    >
                                </v-select>
                                <!-- <div class="px-5">
                                            <PageSalesByMonthTable :year="year" />
                                        </div> -->
                                <div class="px-0">
                                    <PageSalesByMonthChart :year="year" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Sales By Year
                                </v-card-title>
                                <!-- <div class="px-5">
                                                <PageSalesByYearTable :year="year" />
                                            </div> -->
                                <div class="px-0">
                                    <PageSalesByYearChart :year="year" />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Team Sales
                                </v-card-title>
                                <div class="px-5">
                                    <PageTeamSalesTable />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Individual Sales
                                </v-card-title>
                                <div class="px-0">
                                    <PageIndividualSalesTable />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center" style="padding: 5 px;">
                                    Event Sales
                                </v-card-title>
                                <div class="px-0">
                                    <PageEventSalesTable />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Speaker Sales
                                </v-card-title>
                                <div class="px-0">
                                    <PageSpeakerSalesTable />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" sm="12" class="px-5">
                                <v-card-title class="justify-center text-h5 font-weight-bold text-center">
                                    Topic Sales
                                </v-card-title>
                                <div class="px-0">
                                    <PageTopicSalesTable />
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PageSalesByMonthChart from '../analysis/PageSalesByMonthChart.vue';
// import PageSalesByMonthTable from '../analysis/PageSalesByMonthTable.vue';
import PageSalesByYearChart from '../analysis/PageSalesByYearChart.vue';
// import PageSalesByYearTable from '../analysis/PageSalesByYearTable.vue';
import PageIndividualSalesTable from '../analysis/PageIndividualSalesTable.vue';
import PageTeamSalesTable from '../analysis/PageTeamSalesTable.vue';
import PageEventSalesTable from '../analysis/PageEventSalesTable.vue';
import PageSpeakerSalesTable from '../analysis/PageSpeakerSalesTable.vue';
import PageTopicSalesTable from '../analysis/PageTopicSalesTable.vue';
export default {
    components: {
        // PageSalesByMonthTable,
        // PageSalesByYearTable,
        PageSalesByMonthChart,
        PageSalesByYearChart,
        PageIndividualSalesTable,
        PageTeamSalesTable,
        PageEventSalesTable,
        PageSpeakerSalesTable,
        PageTopicSalesTable
    },
    data() {
        const currentYear = new Date().getFullYear();
        const yearOptions = Array.from({ length: currentYear - 2020 }, (_, index) => (currentYear - index).toString());

        return {
            salesTab: null,
            yearOptions: yearOptions,
            year: currentYear.toString(),
        };
    },
    methods: {
       
    }
}
</script>