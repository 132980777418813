<template>
    <v-container>
        <v-card flat>
            <div>
                <v-card-text class="d-flex justify-center align-item ">
                    <v-progress-circular v-if="api.isLoading" :size="50" color="primary"
                        style="align-items: center;position:absolute" indeterminate>
                    </v-progress-circular>
                    <canvas v-show="!api.isLoading" ref="myChart">

                    </canvas>
                </v-card-text>
            </div>
        </v-card>
    </v-container>
</template>
<script>
import Chart from 'chart.js'

export default {
    props: {
        year: String,
    },
    data() {
        return {
            revenueByMonth: null,
            api: {
                isSuccesful: false,
                isError: false,
                isLoading: false,
                error: null,
                url: null,
            },
            trendData: null,
        };
    },
    watch: {
        year: {
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.fetch();
                }
            },
        },
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {

            if (resp.class === "getRevenueByMonth") {
                this.revenueByMonth = resp.data;

                const ctx = this.$refs.myChart.getContext('2d')

                if (this.chart) {
                    this.chart.destroy();
                }

                let filteredData = this.revenueByMonth.filter(entry => entry.year.toString() === this.year);

                let month = filteredData.map(entry => entry.month);
                let total = filteredData.map(entry => entry.total_revenue);

                let formattedTotal = total.map(value => value.toLocaleString());

                this.trendData = [
                    {
                        x: month,
                        y: formattedTotal,
                    }
                ];
                this.trendData = {
                    datasets: [formattedTotal]
                }
                let config = {
                    type: 'line',
                    data: {
                        labels: month,
                        datasets: [
                            {
                                label: 'Total Revenue',
                                data: formattedTotal,
                                borderWidth: 1,
                                borderColor: 'rgb(67, 209, 135)',
                                fill: false,
                            },
                        ]
                    },
                    options: {
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    precision: 0,
                                    callback: function (value) {
                                        return 'RM ' + value.toLocaleString();
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return 'Total Revenue: RM ' + tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                                }
                            }
                        }
                    },
                }
                this.chart = new Chart(ctx, config);
            }

            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchRevenueByMonthApi = this.fetchRevenueByMonth();
            this.$api.fetch(fetchRevenueByMonthApi);
        },
        fetchRevenueByMonth() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/analysis/revenue/month";
            return tempApi;
        },
    }
}
</script>